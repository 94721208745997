import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  FormRule,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Table,
  Tooltip,
} from "antd";
import { useTranslation } from "react-i18next";
import { SelectWithSearch } from "components/form/SelectWithSearch";
import { FixedAsset } from "types/FixedAsset";
import { getTaxes } from "api/Tax";
import { Tax } from "types/Taxes";
import { useMutation } from "react-query";
import { calculateLineTotal } from "api/Bill";
import i18next from "i18next";
import { LineItem } from "types/CommercialDocument";
import { productKeys, taxesKeys } from "tokens/query-keys";
import { getProducts } from "api/Product";
import { DeleteOutlined } from "@ant-design/icons";
import UserContext from "contexts/UserContext";
import { DefaultOptionType } from "antd/es/select";

interface CalculateLineItemResponse {
  amount_after_discount: number;
  tax_value: number;
  tax_percentage: number;
  line_total: number;
  meta?: Record<string, unknown>;
}

interface FormChange {
  product_id: number;
  unit_price: number;
  tax_id: number;
  discount_type: string;
  discount_percentage?: number;
  is_inclusive?: boolean;
  quantity: number;
  indexKey: number;
}

interface LineItemData extends LineItem {
  key?: number;
}

interface CalculateLineTotalParams {
  quantity: number;
  unit_price: number;
  discount_value: number;
  discount_type: string;
  tax: number | string;
  is_inclusive: string;
  meta?: unknown;
}

const InternalLineItem = ({ records }: { records?: LineItem[] }) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();

  const initialDataSource = React.useMemo(() => {
    if (records && records.length > 0) {
      return records?.map((record: LineItem, index) => {
        return {
          ...record,
          key: index,
          quantity: 1,
        } as LineItemData;
      });
    }
    return [{ key: 0 }] as LineItemData[];
  }, [records]);

  const [lineItems, setLineItems] = useState<LineItemData[]>(initialDataSource);
  const [count, setCount] = useState(lineItems.length);

  const getLineItemFieldValue = <T,>(field: string, index: number): T =>
    form.getFieldValue(["bill", "bill_items_attributes", index, field]) as T;

  const handleAdd = () => {
    const newData = {
      key: count,
    } as LineItemData;
    const newItems = [...lineItems, newData];
    setLineItems(newItems);
    form.resetFields([["bill", "bill_items_attributes", newItems.length - 1]]);
    form.resetFields([["discount", newItems.length - 1]]);
    setCount((count) => count + 1);
  };

  const handleDelete = (key: number) => {
    const record = lineItems[key];
    const newData = lineItems.filter((item) => item.key !== record.key);
    setLineItems(newData);
    form.resetFields([["bill", "bill_items_attributes", key]]);
    form.resetFields([["discount", key]]);
  };

  const updateLineItem = (data: CalculateLineItemResponse, index: number) => {
    const lineItem = lineItems[index];
    if (!lineItem) return;

    const newLineItem: LineItem = {
      ...lineItem,
      product_id: getLineItemFieldValue<number>("product_id", index),
      product_description: getLineItemFieldValue<string>(
        "product_description",
        index,
      ),
      quantity: getLineItemFieldValue("quantity", index) || 1,
      unit_price: getLineItemFieldValue("unit_price", index),
      is_inclusive: getLineItemFieldValue("is_inclusive", index),
      discount_percentage: getLineItemFieldValue("discount_percentage", index),
      discount_type: getLineItemFieldValue("discount_type", index),
      total_before_vat:
        data.amount_after_discount ||
        getLineItemFieldValue("total_before_vat", index),
      tax_id:
        getLineItemFieldValue("tax_id", index) ||
        getLineItemFieldValue("tax_id", index),
      vat_value: data.tax_value || getLineItemFieldValue("vat_value", index),
      amount: data.line_total || getLineItemFieldValue("amount", index),
    } as LineItemData;
    const newItems = [...lineItems];
    newItems.splice(index, 1, newLineItem);
    setLineItems(newItems);
  };

  const calculateLineTotalMutation = useMutation<
    CalculateLineItemResponse,
    unknown,
    CalculateLineTotalParams
  >({
    mutationFn: calculateLineTotal,
    onSuccess: (data: CalculateLineItemResponse) => {
      const index = Number(data.meta?.index);
      if (data.line_total < 0) {
        updateLineItem({} as CalculateLineItemResponse, index);
        return;
      }

      form.setFieldValue(
        ["bill", "bill_items_attributes", index, "total_before_vat"],
        data.amount_after_discount,
      );
      form.setFieldValue(
        ["bill", "bill_items_attributes", index, "vat_value"],
        data.tax_value,
      );
      form.setFieldValue(
        ["bill", "bill_items_attributes", index, "amount"],
        data.line_total,
      );

      updateLineItem(data, index);
    },
  });

  const handleChange = (values: FormChange[]) => {
    if (!values) return;

    values.forEach((value) => {
      calculateLineTotalMutation.mutate({
        quantity: value.quantity || 1,
        unit_price: Number(value.unit_price),
        discount_value: value.discount_percentage || 0,
        discount_type: value.discount_type || "percentage",
        tax: value.tax_id,
        is_inclusive: value.is_inclusive ? "true" : "false",
        meta: { index: value.indexKey },
      });
    });
  };

  const handleProductChange =
    (index: number) => (val: string, record: DefaultOptionType) => {
      form.setFieldValue(
        ["bill", "bill_items_attributes", index, "product_description"],
        record.description,
      );
      form.setFieldValue(
        ["bill", "bill_items_attributes", index, "unit_price"],
        record.buying_price || record.selling_price,
      );
      form.setFieldValue(
        ["bill", "bill_items_attributes", index, "tax_id"],
        record.tax_id,
      );
      form.setFieldValue(
        ["bill", "bill_items_attributes", index, "discount_type"],
        "percentage",
      );
      form.setFieldValue(
        ["bill", "bill_items_attributes", index, "quantity"],
        1,
      );
      handleChange([
        {
          product_id: Number(val),
          unit_price: record.buying_price as number,
          tax_id: record.tax_id as number,
          discount_type: "percentage",
          quantity: 1,
          indexKey: index,
        },
      ]);
    };

  const formChange = Form.useWatch<FormChange[]>(
    ["bill", "bill_items_attributes"],
    form,
  );
  React.useEffect(() => {
    handleChange(formChange);
  }, [form, formChange]);

  const currentUser = React.useContext(UserContext);
  const maxDiscount =
    currentUser?.userType === "Merchant" ? 100 : currentUser?.discount || 0;

  const columns = React.useMemo(() => {
    const items = [
      {
        key: "product_id",
        dataIndex: "product_id",
        title: t("activerecord.attributes.credit_note.products"),
        width: "20%",
        render: (product_id: number, record: LineItem, index: number) => (
          <>
            <Form.Item
              hidden
              name={["bill", "bill_items_attributes", index, "indexKey"]}
              initialValue={index}
            >
              <Input type="hidden" value={index} />
            </Form.Item>
            <Form.Item
              name={["bill", "bill_items_attributes", index, "product_id"]}
              initialValue={Number(product_id) || null}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true }]}
            >
              <SelectWithSearch
                initialOpts={product_id ? [record?.product] : []}
                onChange={handleProductChange(index)}
                queryFn={getProducts}
                queryKeyObject={productKeys}
                filters={{
                  "q[track_quantity_true]": false,
                  "q[purchase_item_true]": true,
                }}
                dataLabelFn={(product: FixedAsset) =>
                  i18next.language === "en"
                    ? product.en_name || product.name
                    : product.name || product.en_name
                }
              />
            </Form.Item>
          </>
        ),
      },
      {
        key: "product_description",
        dataIndex: "product_description",
        title: t("activerecord.attributes.credit_note.product_description"),
        width: "15%",
        render: (text: string, _record: LineItem, index: number) => (
          <Form.Item
            name={[
              "bill",
              "bill_items_attributes",
              index,
              "product_description",
            ]}
            initialValue={text}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
        ),
      },
      {
        key: "quantity",
        dataIndex: "quantity",
        title: t("activerecord.attributes.credit_note.qty"),
        render: (text: number, _record: LineItem, index: number) => (
          <Form.Item
            initialValue={text}
            wrapperCol={{ span: 24 }}
            name={["bill", "bill_items_attributes", index, "quantity"]}
            rules={[
              { required: true },
              {
                validator: (_rule: FormRule, value: number) => {
                  if (value < 1)
                    return Promise.reject(
                      new Error(
                        t(
                          "activerecord.errors.models.internal_line_item.attributes.value.value_greater_than",
                        ),
                      ),
                    );

                  return Promise.resolve();
                },
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
        ),
      },
      {
        key: "unit_price",
        dataIndex: "unit_price",
        title: t("activerecord.attributes.credit_note.unit_price"),
        render: (text: string, _record: LineItem, index: number) => (
          <Form.Item
            name={["bill", "bill_items_attributes", index, "unit_price"]}
            initialValue={text}
            rules={[{ required: true }]}
          >
            <InputNumber min={0.0} />
          </Form.Item>
        ),
      },
      {
        key: "is_inclusive",
        dataIndex: "is_inclusive",
        title: t("shorts.is_inclusive"),
        render: (val: boolean, _record: LineItem, index: number) => (
          <Form.Item
            valuePropName="checked"
            name={["bill", "bill_items_attributes", index, "is_inclusive"]}
            initialValue={val}
            wrapperCol={{ span: 24 }}
          >
            <Checkbox />
          </Form.Item>
        ),
      },
      {
        key: "discount_percentage",
        dataIndex: "discount_percentage",
        title: t("activerecord.attributes.credit_note.discount"),
        className: "addon-select",
        width: "30%",
        render: (text: number, record: LineItem, index: number) => (
          <React.Fragment>
            <Form.Item
              wrapperCol={{ span: 24 }}
              name={["discount", index]}
              initialValue={text}
              className="addon-select"
              rules={[
                {
                  validator: (_rule: FormRule, value: number) => {
                    if (!value) return Promise.resolve();

                    const discountType = form.getFieldValue([
                      "bill",
                      "bill_items_attributes",
                      index,
                      "discount_type",
                    ]) as string;

                    const errorMsg = t(
                      "frontend.validations.discount_per_error",
                    ).replace("%{max}", `${maxDiscount}`);

                    if (discountType === "percentage" && value > maxDiscount) {
                      return Promise.reject(new Error(errorMsg));
                    }

                    const unitPrice =
                      (form.getFieldValue([
                        "bill",
                        "bill_items_attributes",
                        index,
                        "unit_price",
                      ]) as number) || 0;
                    const quantity =
                      (form.getFieldValue([
                        "bill",
                        "bill_items_attributes",
                        index,
                        "quantity",
                      ]) as number) || 1;
                    const total = Number(unitPrice) * Number(quantity);
                    const discountInPercentage = (value * 100.0) / total;
                    if (
                      discountType === "value" &&
                      discountInPercentage > maxDiscount
                    ) {
                      return Promise.reject(
                        new Error(t("frontend.validations.discount_amount")),
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber
                min={0.0}
                onChange={(val: number | null) =>
                  form.setFieldValue(
                    [
                      "bill",
                      "bill_items_attributes",
                      index,
                      "discount_percentage",
                    ],
                    val,
                  )
                }
                addonAfter={
                  <Select
                    style={{ width: "100%" }}
                    onChange={(val: string) =>
                      form.setFieldValue(
                        [
                          "bill",
                          "bill_items_attributes",
                          index,
                          "discount_type",
                        ],
                        val,
                      )
                    }
                    defaultValue={
                      lineItems[index]?.discount_type || "percentage"
                    }
                    options={[
                      { value: "percentage", label: "%" },
                      {
                        value: "value",
                        label: t("activerecord.attributes.subscription.sar"),
                      },
                    ]}
                  />
                }
              />
            </Form.Item>
            <Form.Item
              hidden
              name={[
                "bill",
                "bill_items_attributes",
                index,
                "discount_percentage",
              ]}
              initialValue={text}
            >
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              hidden
              name={["bill", "bill_items_attributes", index, "discount_type"]}
              initialValue={record?.discount_type}
            >
              <Input type="hidden" />
            </Form.Item>
          </React.Fragment>
        ),
      },
      {
        key: "total_before_vat",
        dataIndex: "total_before_vat",
        title: t("activerecord.attributes.credit_note.total_before_vat"),
        render: (text: string, _record: LineItem, index: number) => (
          <Form.Item
            name={["bill", "bill_items_attributes", index, "total_before_vat"]}
            initialValue={text}
          >
            <InputNumber min={0.0} disabled />
          </Form.Item>
        ),
      },
      {
        key: "tax_id",
        dataIndex: "tax_id",
        title: t("activerecord.attributes.credit_note.vat_percentage"),
        width: "20%",
        render: (text: string, _record: LineItem, index: number) => (
          <Form.Item
            wrapperCol={{ span: 24 }}
            name={["bill", "bill_items_attributes", index, "tax_id"]}
            initialValue={Number(text) || null}
            rules={[{ required: true }]}
          >
            <SelectWithSearch
              queryFn={getTaxes}
              queryKeyObject={taxesKeys}
              dataLabelFn={(tax: Tax) => tax.code_name || ""}
            />
          </Form.Item>
        ),
      },
      {
        key: "vat_value",
        dataIndex: "vat_value",
        title: t("activerecord.attributes.credit_note.vat_value"),
        render: (text: string, _record: LineItem, index: number) => (
          <Form.Item
            name={["bill", "bill_items_attributes", index, "vat_value"]}
            initialValue={text}
          >
            <InputNumber min={0.0} disabled />
          </Form.Item>
        ),
      },
      {
        key: "amount",
        dataIndex: "amount",
        title: t("activerecord.attributes.credit_note.amount"),
        render: (text: string, _record: LineItem, index: number) => (
          <Form.Item
            name={["bill", "bill_items_attributes", index, "amount"]}
            initialValue={text}
          >
            <InputNumber min={0.0} disabled />
          </Form.Item>
        ),
      },
    ];

    if (lineItems.length > 1) {
      items.push({
        key: "actions",
        dataIndex: "actions",
        title: t("activerecord.attributes.fixed_asset.action"),
        render: (_text: string, _record: LineItem, index: number) => (
          <Popconfirm
            title={t("templates.sure_modal")}
            onConfirm={() => handleDelete(index)}
          >
            <Tooltip placement="bottom" title={t("tooltip.delete")}>
              <Button
                icon={<DeleteOutlined />}
                danger
                style={{ marginBottom: 24 }}
              />
            </Tooltip>
          </Popconfirm>
        ),
      });
    }

    return items;
  }, [lineItems]);

  return (
    <Table
      key="line-item-table"
      size="small"
      dataSource={lineItems}
      columns={columns}
      style={{ width: "100%" }}
      pagination={false}
      className="modal-form"
      scroll={{ x: true }}
      footer={() => (
        <Button type="primary" onClick={handleAdd}>
          {t("forms.buttons.add_more")}
        </Button>
      )}
    />
  );
};

export default InternalLineItem;
