import React from "react";
import { Form, Input } from "antd";

import "../styles.css";
import { AssetRegistry } from "types/AssetRegistry";
import { UseMutationResult } from "react-query";
import { FixedAssetAddition } from "types/FixedAssetAddition";
import RecordContext from "contexts/RecordContext";
import GeneralForm from "../GeneralForm";
import { CalculateDeprecation } from "../CalculateDepreciation";
import BillForm from "./BillForm";
import { Bill } from "types/CommercialDocument";
import { AssetRegistryList } from "../LinkBill/AssetRegistryList";
import { AxiosError } from "axios";
import { ApiError } from "types/Error";

export const FixedAssetAdditionCreateBillForm = ({
  mutation,
}: {
  mutation: UseMutationResult<
    FixedAssetAddition,
    AxiosError<ApiError>,
    HTMLFormElement,
    unknown
  >;
}) => {
  const record = React.useContext(RecordContext) as FixedAssetAddition;
  const transaction = record?.fixed_asset_addition_transactions?.find(
    (tr) => tr.transactionable_type === "Bill",
  );
  const depreciationTransaction =
    record?.fixed_asset_addition_transactions?.find(
      (tr) => tr.transactionable_type === "Depreciation",
    );

  const showMergeRegistries = (mainRegistry: AssetRegistry) => {
    return <AssetRegistryList mainRegistry={mainRegistry} />;
  };

  const calculateDepreciationComponent = (mainRegistry: AssetRegistry) => {
    return (
      <CalculateDeprecation
        registries={[mainRegistry]}
        depreciationID={depreciationTransaction?.transactionable_id}
      />
    );
  };

  return (
    <GeneralForm
      record={record}
      mutation={mutation}
      renderListRegistryComponent={showMergeRegistries}
      renderCalculateDepreciationComponent={calculateDepreciationComponent}
      additionType="create_bill"
    >
      <Form.Item
        hidden
        name={["fixed_asset_addition", "addition_type"]}
        initialValue="create_bill"
      >
        <Input type="hidden" />
      </Form.Item>
      <BillForm
        record={
          (transaction?.transactionable ||
            transaction?.attributes?.bill) as Bill
        }
      />
    </GeneralForm>
  );
};
