/* eslint-disable */
//@ts-nocheck
import React, {useContext, useState, useEffect, useCallback} from 'react';
import QLayoutContext from 'contexts/Layout';
import type { MenuProps, TourStepProps } from 'antd';
import GAMap from 'tokens/GAMap.json';
import ReactGA from 'react-ga4';

import i18next, { TFunction } from 'i18next';
import moment from 'moment';

import { Card, Space, Typography, theme, Empty, Button } from 'antd';
import { ClockCircleOutlined, EditOutlined, EyeOutlined, GlobalOutlined, LogoutOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const {Text, Paragraph, Link, Title} = Typography;
const {useToken} = theme;

const { Meta } = Card;

export const useComments = (): MenuProps['items'] => {
    const { comments } = useContext(QLayoutContext);
    const { t } = useTranslation();

    const {viewAllPath, lastComments} = comments;

    const {token} = useToken();

    let items: MenuProps['items'] = lastComments?.map(({user_full_name, path, content, created_at}) => ({
        key: path,
        label: (
            <Link style={{wordBreak: 'unset'}} onClick={() => {
                ReactGA.event({
                    category: GAMap.header.CATEGORY,
                    action: GAMap.header.mentions.open
                });
            }} href={path} key={path}>
                <Card style={{border: 'none', borderRadius: 0}} bodyStyle={{ paddingInline: token.padding }}>
                    <Meta
                        title={<Title level={5} style={{margin: 0, whiteSpace: 'break-spaces'}}>{user_full_name}</Title>}
                        description={<Space direction="vertical" size={16}><Paragraph style={{wordBreak: 'unset', width: '100%', margin: 0}} ellipsis={{ rows: 2 }} type="secondary">{content}</Paragraph><Space size={8}><ClockCircleOutlined /><Text style={{wordBreak: 'unset'}} type="secondary">{created_at}</Text></Space></Space>}
                    />
                </Card>
            </Link>
        ),
        style: {paddingInline: 0, borderTop: '1px solid rgba(0, 0, 0, 10%)', borderRadius: 0}
    }))

    items = items && items.length? items : [{key: 'Empty', label: <Empty key='Empty' image={Empty.PRESENTED_IMAGE_SIMPLE} />}];

    return [
            {
                key: '1',
                label: (
                    <Space style={{width: '100%', justifyContent: 'space-between', paddingBlock: 14}} size={8} key='1'>
                        <Text style={{wordBreak: 'unset'}} strong>{t('activerecord.attributes.comment.mention_list')}</Text>
                        <Space size={8}>
                            <EyeOutlined />
                            <Link style={{wordBreak: 'unset'}} target="_blank" rel="noopener noreferrer" onClick={() => {
                                ReactGA.event({
                                    category: GAMap.header.CATEGORY,
                                    action: GAMap.header.mentions.viewAll
                                });
                            }} href={viewAllPath}>
                                {t('templates.tasks.view_all')}
                            </Link>
                        </Space>
                    </Space>
                ),
                type: 'group'
            },
            ...items
        ]
}

export const useTasks = (): {tasks: MenuProps['items'], getLatestTasks: () => void} => {
    const { tasks } = useContext(QLayoutContext);
    const { t } = useTranslation();

    const {viewAllPath, newTaskPath} = tasks;
    const [lastTasks, setLastTasks] = useState(null);

    const {token} = useToken();

    const getLatestTasks = useCallback(() => {
        axios
            .get('/tenant/tasks.json?q[sorts]=created_at%20desc&per_page=5')
            .then((response) => {
                setLastTasks(response.data?.tasks);
            })
    }, [])

    let items: MenuProps['items'] = lastTasks?.map(({description, name, updated_at, id}) => ({
        key: id,
        label: (
            <Link style={{wordBreak: 'unset'}} onClick={() => {
                ReactGA.event({
                    category: GAMap.header.CATEGORY,
                    action: GAMap.header.tasks.open
                });
            }} href={`/tenant/tasks/${id}`} key={`task-${id}`}>
                <Card style={{border: 'none', borderRadius: 0}} bodyStyle={{ paddingInline: token.padding }}>
                    <Meta
                        title={<Title level={5} style={{margin: 0, whiteSpace: 'break-spaces'}}>{name}</Title>}
                        description={<Space direction="vertical" size={16}><Paragraph style={{wordBreak: 'unset', width: '100%', margin: 0}} ellipsis={{ rows: 2 }} type="secondary">{description}</Paragraph><Space size={8}><ClockCircleOutlined /><Text style={{wordBreak: 'unset'}} type="secondary">{moment.duration(moment().diff(updated_at)).humanize()}</Text></Space></Space>}
                    />
                </Card>
            </Link>
        ),
        style: {paddingInline: 0, borderTop: '1px solid rgba(0, 0, 0, 10%)', borderRadius: 0}
    }))

    items = items && items.length? items : [{key: 'Empty', label: <Empty key='Empty' image={Empty.PRESENTED_IMAGE_SIMPLE} />}];
    const finalizedTasks: MenuProps['items'] = [
            {
                key: '1',
                label: (
                    <Space style={{width: '100%', justifyContent: 'space-between', paddingBlock: 14}} size={8} key='1'>
                        <Text style={{wordBreak: 'unset'}} strong>{t('templates.tasks.recent_task')}</Text>
                        <Space size={16}>
                            <Space size={8}>
                                <EyeOutlined />
                                <Link style={{wordBreak: 'unset'}} onClick={() => {
                                        ReactGA.event({
                                            category: GAMap.header.CATEGORY,
                                            action: GAMap.header.tasks.viewAll
                                        });
                                    }} target="_blank" rel="noopener noreferrer" href={viewAllPath}>
                                    {t('templates.tasks.view_all')}
                                </Link>
                            </Space>
                            {newTaskPath? <Button onClick={(e) => {
                                                e.preventDefault();
                                                axios.get(newTaskPath, {
                                                    headers: {
                                                        'Accept': '*/*;q=0.5, text/javascript, application/javascript, application/ecmascript, application/x-ecmascript'
                                                    }
                                                }).then(response => {
                                                    const script = document.createElement('script');
                                                    script.innerHTML = response.data;
                                                    document.body.append(script);
                                                })
                                                ReactGA.event({
                                                    category: GAMap.header.CATEGORY,
                                                    action: GAMap.header.tasks.create
                                                });
                                            }} href={newTaskPath} icon={<PlusOutlined />}>{t('templates.tasks.new_task')}</Button> : null}
                        </Space>
                    </Space>
                ),
                type: 'group'
            },
            ...items
        ]

    return { tasks: finalizedTasks, getLatestTasks}
}



export const useHelp = (broken: boolean, startIntro: () => {}) => {
    const { isOnBoard, sideMenuData, canSendSuggestions } = useContext(QLayoutContext);
    const {t} = useTranslation();
    const rawItems: any = [
        {id: 'qoyodUpdates', label: 'frontend.tooltips.on_boarding.new_releases', href: 'https://releases.qoyod.com'},
        {id: 'videoTutorials', label: 'frontend.tooltips.on_boarding.video_tutorials', href: 'https://www.youtube.com/watch?v=FiOdXWOVnFs'},
        {id: 'helpCenter', label: 'frontend.tooltips.on_boarding.knowledge_base', href: i18next.language === 'en'? 'https://www.qoyod.com/en/help-center/' : 'https://www.qoyod.com/ara/مركز-المساعدة'},
    ]
    const hasShowMeHow = sideMenuData.find(item => item?.props?.id === 'sidepanel_show_me_how');

    if(hasShowMeHow && !broken) {
        rawItems.push({id: 'showMeHow', label: 'activerecord.attributes.help_content.btn_H', href: '#', target: '_self', onClick: () => {
            startIntro();
            ReactGA.event({
                category: GAMap.header.CATEGORY,
                action: GAMap.header.help.showMeHow
            });
            ReactGA.event({
                category: GAMap.showMeHow.CATEGORY,
                action: GAMap.showMeHow[window.location.pathname]
            });
        }});
    }

    if(!isOnBoard) {
        rawItems.push({id: 'onBoarding', label: 'frontend.tooltips.on_boarding.continue_onboarding', href: '/tenant/onboarding'});
    }

    if(canSendSuggestions){
      rawItems.push({id: 'suggestionHub', label: 'frontend.tooltips.on_boarding.suggestion_hub', href: '/tenant/dashboards/suggestion_hub' });
    }

    const items: MenuProps['items'] = rawItems.map(({id, label, href, ...props}) => ({
        key: id,
        label: (
            <Link style={{wordBreak: 'unset'}} target="_blank" rel="noopener noreferrer" onClick={() => {
                ReactGA.event({
                    category: GAMap.header.CATEGORY,
                    action: GAMap.header.help[id]
                });
            }} href={href} key={id} {...props}>
                {t(label)}
            </Link>
        )
    }))

    return items;
}

const langMap = new Map([
    ['en', {label: 'العربية', id: 'ar'}],
    ['ar', {label: 'English', id: 'en'}]
])

export const useUserSetting = () => {
    const { currentUser } = useContext(QLayoutContext);
    const {t} = useTranslation();
    const {token} = useToken();

    const lang = langMap.get(i18next.language);

    const handleLocaleChange = useCallback((lang, e) => {
        e.preventDefault();
        const url = new URL(window.location.href);
        url.searchParams.set('locale', lang);
        window.location.replace(url);
    }, [])

    const rawItems = [
        {id: 'editProfile', label: 'activerecord.attributes.layout.edite_prifle_top_menu', href: currentUser.href, icon: <EditOutlined />},
        {id: lang.id, label: lang.label, onClick: handleLocaleChange.bind(null, lang.id), style: {fontFamily: 'Cairo'}, icon: <GlobalOutlined />},
        {divider: true},
        {id: 'myOrganizations', text: 'activerecord.attributes.layout.my_organizations', disabled: true},
        {id: 'changeOrganization', label: 'activerecord.attributes.layout.change_organization_top_menu', href: '/tenant/dashboards/organizations', icon: <SyncOutlined />},
        {id: 'addOrganization', label: 'activerecord.attributes.layout.add_organization', href: '/home/select_plan', icon: <PlusOutlined />},
        {divider: true},
        {id: 'logout', label: 'forms.buttons.logout', href: '/users/sign_out', 'data-method': 'delete', icon: <LogoutOutlined />, danger: true},
    ]

    const items: MenuProps['items'] = rawItems.map(item => {
        if (item.divider) {
            return {type: 'divider'}
        }
        const { id, label, href, icon, danger, disabled, ...props } = item;
        return {
            key: id,
            label: id === 'myOrganizations' ? <Text type="secondary">{t(item.text)}</Text> : (
                <Link style={{ wordBreak: 'unset' }} onClick={() => {
                    ReactGA.event({
                        category: GAMap.header.CATEGORY,
                        action: GAMap.header.account[id]
                    });
                }} rel="noopener noreferrer" href={href} key={id} {...props}>
                    {t(label)}
                </Link>
            ),
            icon: icon,
            danger: danger || false,
            disabled: disabled || false,
            style: {cursor: disabled && "default"}
        };
    })

    return items;
}



interface Intro {
    steps: null | TourStepProps[];
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const tourMap = new Map<string, TourStepProps[]>([
    ['/tenant/dashboards', [
        {
            title: 'activerecord.attributes.layout.dashboard',
            description: 'activerecord.attributes.help_content.qoyodintro_h',
            target: () => null,
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.dashboard',
            description: 'activerecord.attributes.help_content.Payment_Snapshot_h',
            target: () => document.querySelector('#step1'),
            placement: 'right'
        },
        {
            title: 'activerecord.attributes.layout.dashboard',
            description: 'activerecord.attributes.help_content.revenue_expense_h',
            target: () => document.querySelector('#step2'),
            placement: 'right'
        },
        {
            title: 'activerecord.attributes.layout.dashboard',
            description: 'activerecord.attributes.help_content.customer_h',
            target: () => document.querySelector('#step3'),
            placement: 'right'
        },
        {
            title: 'activerecord.attributes.layout.dashboard',
            description: 'activerecord.attributes.help_content.vendor_h',
            target: () => document.querySelector('#step4'),
            placement: 'right'
        },
        {
            title: 'activerecord.attributes.layout.dashboard',
            description: 'activerecord.attributes.help_content.activity_log_h',
            target: () => document.querySelector('#step5'),
            placement: 'bottom'
        },
    ]],
    ['/tenant/products', [
        {
            title: 'activerecord.attributes.layout.products',
            description: 'activerecord.attributes.help_content.product_intro',
            target: () => null,
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.products',
            description: 'activerecord.attributes.help_content.product_th',
            target: () => document.querySelector('#step1'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.products',
            description: 'activerecord.attributes.help_content.product_action',
            target: () => document.querySelector('#step2'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.products',
            description: 'activerecord.attributes.help_content.btn_product_categories',
            target: () => document.querySelector('#step3'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.products',
            description: 'activerecord.attributes.help_content.btn_unit_types',
            target: () => document.querySelector('#step4'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.products',
            description: 'activerecord.attributes.help_content.btn_add_product',
            target: () => document.querySelector('#step5'),
            placement: 'bottom'
        },
    ]],
    ['/tenant/manual_journal_entries', [
        {
            title: 'activerecord.attributes.layout.manual_journal_entries',
            description: 'activerecord.attributes.help_content.manual_journal_entries_intro',
            target: () => null,
            placement: 'bottom'
        }
    ]],
    ['/tenant/easy_entries', [
        {
            title: 'activerecord.attributes.layout.easy_entries',
            description: 'activerecord.attributes.help_content.manual_journal_entries_intro',
            target: () => null,
            placement: 'bottom'
        }
    ]],
    ['/tenant/contacts', [
        {
            title: 'activerecord.attributes.layout.customers',
            description: 'activerecord.attributes.help_content.contact_intro',
            target: () => null,
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.customers',
            description: 'activerecord.attributes.help_content.contact_tbl',
            target: () => document.querySelector('#step1'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.customers',
            description: 'activerecord.attributes.help_content.contact_action',
            target: () => document.querySelector('#step2'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.customers',
            description: 'activerecord.attributes.help_content.btn_add_contact',
            target: () => document.querySelector('#step3'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.customers',
            description: 'activerecord.attributes.help_content.btn_contact_export',
            target: () => document.querySelector('#step4'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.customers',
            description: 'activerecord.attributes.help_content.btn_contact_import',
            target: () => document.querySelector('#step5'),
            placement: 'bottom'
        },
    ]],
    ['/tenant/contacts/vendor_index', [
        {
            title: 'activerecord.attributes.layout.vendors',
            description: 'activerecord.attributes.help_content.vendor_intro',
            target: () => null,
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.vendors',
            description: 'activerecord.attributes.help_content.vendor_tbl',
            target: () => document.querySelector('#step1'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.vendors',
            description: 'activerecord.attributes.help_content.vendor_action',
            target: () => document.querySelector('#step2'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.vendors',
            description: 'activerecord.attributes.help_content.btn_add_vendor',
            target: () => document.querySelector('#step3'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.vendors',
            description: 'activerecord.attributes.help_content.btn_vendor_export',
            target: () => document.querySelector('#step4'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.vendors',
            description: 'activerecord.attributes.help_content.btn_vendor_import',
            target: () => document.querySelector('#step5'),
            placement: 'bottom'
        },
    ]],
    ['/tenant/quotations', [
        {
            title: 'activerecord.attributes.layout.quotations',
            description: 'activerecord.attributes.help_content.quotes_intro',
            target: () => null,
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.quotations',
            description: 'activerecord.attributes.help_content.quotes_add',
            target: () => document.querySelector('#step1'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.quotations',
            description: 'activerecord.attributes.help_content.quotes_action',
            target: () => document.querySelector('#step2'),
            placement: 'bottom'
        }
    ]],
    ['/tenant/purchase_orders', [
        {
            title: 'activerecord.attributes.layout.orders',
            description: 'activerecord.attributes.help_content.mange_order',
            target: () => null,
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.orders',
            description: 'activerecord.attributes.help_content.oredr_new',
            target: () => document.querySelector('#step1'),
            placement: 'bottom'
        }
    ]],
    ['/tenant/invoices', [
        {
            title: 'activerecord.attributes.layout.invoices',
            description: 'activerecord.attributes.help_content.invoices_intro',
            target: () => null,
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.invoices',
            description: 'activerecord.attributes.help_content.invoices_graph',
            target: () => document.querySelector('#step1'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.invoices',
            description: 'activerecord.attributes.help_content.invoices_tbl',
            target: () => document.querySelector('#step2'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.invoices',
            description: 'activerecord.attributes.help_content.invoices_balance',
            target: () => document.querySelector('#step3'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.invoices',
            description: 'activerecord.attributes.help_content.invoices_action',
            target: () => document.querySelector('#step4'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.invoices',
            description: 'activerecord.attributes.help_content.invoices_btn_add',
            target: () => document.querySelector('#step5'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.invoices',
            description: 'activerecord.attributes.help_content.invoices_btn_manage_credit_note',
            target: () => document.querySelector('#step6'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.invoices',
            description: 'activerecord.attributes.help_content.invoices_manage_payment',
            target: () => document.querySelector('#step7'),
            placement: 'bottom'
        },
    ]],
    ['/tenant/bills', [
        {
            title: 'activerecord.attributes.layout.bills',
            description: 'activerecord.attributes.help_content.bill_intro',
            target: () => null,
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.bills',
            description: 'activerecord.attributes.help_content.bill_graph',
            target: () => document.querySelector('#step1'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.bills',
            description: 'activerecord.attributes.help_content.bill_tbl',
            target: () => document.querySelector('#step2'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.bills',
            description: 'activerecord.attributes.help_content.bill_action',
            target: () => document.querySelector('#step3'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.bills',
            description: 'activerecord.attributes.help_content.bill_credit',
            target: () => document.querySelector('#step4'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.bills',
            description: 'activerecord.attributes.help_content.bill_add',
            target: () => document.querySelector('#step5'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.bills',
            description: 'activerecord.attributes.help_content.bill_management',
            target: () => document.querySelector('#step6'),
            placement: 'bottom'
        }
    ]],
    ['/tenant/debit_notes', [
        {
            title: 'activerecord.attributes.layout.debit_notes',
            description: 'activerecord.attributes.help_content.debit_notes_intro',
            target: () => null,
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.debit_notes',
            description: 'activerecord.attributes.help_content.debit_notes_new_btn',
            target: () => document.querySelector('#step1'),
            placement: 'bottom'
        },
        {
            title: 'activerecord.attributes.layout.debit_notes',
            description: 'activerecord.attributes.help_content.debit_notes_action',
            target: () => document.querySelector('#step2'),
            placement: 'bottom'
        }
    ]],
])


const getSteps = (pagePath: string, t: TFunction<"translation", string>): TourStepProps[] => {
    const rawSteps = tourMap.get(pagePath);
    const steps = rawSteps?.map((step, idx) => ({
        ...step,
        title: t(step.title as string),
        description: t(step.description as string),
        nextButtonProps: {
            children: idx === rawSteps.length - 1 ? t('activerecord.attributes.layout.finish') : t('activerecord.attributes.layout.next'),
            style: {padding: '4px 8px', height: 'auto'}

        },
        prevButtonProps: {
            children: t('activerecord.attributes.layout.previous'),
            style: {padding: '4px 8px', height: 'auto'}

        }
    }))
    return steps || [];
}

export const useIntro = (pagePath: string): Intro => {

    const [open, setOpen] = useState(false);
    const [steps, setSteps] = useState([]);

    useEffect(() => {
        setSteps(getSteps(pagePath, t))
    }, []);

    const {t} = useTranslation();
    return {
        steps,
        open,
        setOpen
    };
}
/* eslint-enable */
